import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import Cover from '../../components/Cover/Cover';
import { Seo } from '../../components/Seo';
import Layout from '../../layouts/layout';
import * as classes from './features.module.scss';

const ArchivePage = props => {
    const { t } = useTranslation();

    return <Layout location={props.location}>
        <Cover
            title={<Trans i18nKey="archive-page:pageTitle">Archive - Document Management</Trans>}
            subtitle={<Trans i18nKey="archive-page:pageSubtitle">Optimize business efficiency: embrace the future with
                                                                 AIDA's Advanced Document Archive</Trans>}
            cover="archive"
        />
        <div className="container p-0 intro">
            <Trans i18nKey="archive-page:pageDescription">
                <p>Streamline your document management processes with AIDA's advanced <strong>document
                                                                                              archive</strong> feature,
                   designed
                   to cater to the modern business's needs.</p>
                <p>Our cutting-edge solution offers a comprehensive range of capabilities, including <strong>indexing of
                                                                                                             full-text
                                                                                                             OCR and
                                                                                                             extracted
                                                                                                             fields</strong>, <strong>unlimited
                                                                                                                                      cloud
                                                                                                                                      storage</strong>,
                   complete document lifecycle control, and seamless
                   integrations with your existing workflows.</p>
                <p>AIDA's document archive is the <strong>best-in-class tool</strong> for businesses looking to enhance
                   efficiency,
                   improve collaboration, and ensure secure and long-term storage of sensitive information.</p>
            </Trans>
        </div>
        <div className={classes.FeaturesContainer}>
            <div className={classes.Features}>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/archive/aida-archive-document-management-01-unlimited-archive.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <a id="unlimitedStorage" className="anchor" href="#unlimitedStorage">.</a>
                            <div className={classes.SmallTitle}>{t('archive-page:pageTitle')}</div>
                            <h2><Trans>Unlimited storage and automated file organization for enhanced Document
                                       Management</Trans></h2>
                            <Trans i18nKey="archive-page:feature1">
                                <p>AIDA's document archive provides <strong>unlimited storage space</strong>, allowing
                                   your business to grow without worrying about storage limitations.</p>
                                <p>Our smart system <Link to="/platform/data-extraction#automaticArchive">automatically
                                                                                                          renames
                                                                                                          files</Link> based
                                   on
                                   extracted fields and rules you define, ensuring a consistent and efficient
                                   organization of documents.</p>

                                <p>With AIDA, you can effortlessly manage large volumes of documents across multiple
                                   departments or projects. The cloud-based storage system ensures <Link
                                        to="/platform/security-and-certifications">accessibility and
                                                                                   security</Link> while eliminating the
                                   need for physical storage and manual organization, streamlining your document
                                   management processes.</p>

                                <p>Furthermore, with <Link to="/platform/aida-mobile">AIDA Mobile</Link>, you can access
                                   the archive on your
                                   smartphone, ensuring you have the information you need at your fingertips, no matter
                                   where you are. This added convenience makes it even easier to stay organized and in
                                   control of your documents, all within the same efficient platform.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('archive-page:pageTitle')}</div>
                            <h2><Trans>Advanced full-Text OCR search engine for fast and accurate document
                                       retrieval</Trans></h2>
                            <Trans i18nKey="archive-page:feature2">
                                <p>AIDA's robust search engine enables users to search both extracted fields and the
                                   entire text content of documents, utilizing <strong>full-text OCR
                                                                                       capabilities</strong>.</p>
                                <p>Our <strong>advanced query support</strong>, powered by cutting-edge technology,
                                   guarantees quick and accurate results for even the most complex searches.</p>
                                <p>Effortlessly locate essential documents in a matter of seconds with our powerful
                                   search functionality, reducing the time spent searching for files and improving
                                   overall productivity.</p>
                                <p>AIDA's search engine adapts to your specific requirements, ensuring that you can
                                   easily find the information you need <strong>when you need it</strong>.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/archive/aida-archive-document-management-02-full-text-ocr-search-index.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/archive/aida-archive-document-management-03-documents-relations-workflows.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('archive-page:pageTitle')}</div>
                            <h2><Trans>Enhance workflows and knowledge building with document relations</Trans></h2>
                            <Trans i18nKey="archive-page:feature3">
                                <p>AIDA's document archive doesn't make use of folders, it rather promote the creation
                                   of knowledge graphs through <Link to="/platform/relations-knowledge-graph">relations
                                                                                                              between
                                                                                                              documents</Link>,
                                   streamlining workflows and fostering knowledge building within your organization.</p>
                                <p>This <strong>interconnected approach</strong> allows for a comprehensive
                                   understanding of complex information and promotes collaboration among team members.
                                </p>
                                <p>Improve decision-making and optimize internal processes by leveraging the <strong>knowledge
                                                                                                                     graph</strong> feature
                                   to identify patterns, trends, and relationships within your documents.</p>
                                <p>AIDA's <strong>innovative approach</strong> to document management helps your
                                   organization stay ahead of the curve and adapt to changing industry demands.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <a id="combinedHistory" className="anchor" href="#combinedHistory">.</a>
                            <div className={classes.SmallTitle}>{t('archive-page:pageTitle')}</div>
                            <h2><Trans>Maintain complete transparency and control with comprehensive document
                                       history</Trans></h2>
                            <Trans i18nKey="archive-page:feature4">
                                <p>AIDA's document archive offers a <strong>detailed history</strong> of each document,
                                   tracking its origin, destination, and actions taken, ensuring full control and
                                   simplifying compliance audits.</p>
                                <p>The <strong>combined document history</strong> integrates relevant date fields,
                                   providing a streamlined view of the document lifecycle. This feature assists in
                                   managing commons deadlines such as payment dates and other similar due dates,
                                   simplifying the tracking and organization of crucial information for more efficient
                                   business processes.</p>
                                <p>Stay informed with AIDA's document history to monitor compliance and understand your
                                   organization's information flow and identify potential bottlenecks.</p>
                                <p>With AIDA, you can confidently manage your business's documents while ensuring
                                   regulatory adherence and minimizing potential risks.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/archive/aida-archive-document-management-04-complete-history-compliance-control.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/archive/aida-archive-document-management-05-customizable-columns-view.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('archive-page:pageTitle')}</div>
                            <h2><Trans>Customize columns and sort results for a tailored document management
                                       experience</Trans></h2>
                            <Trans i18nKey="archive-page:feature5">
                                <p>AIDA's document archive allows for <strong>columns customization</strong> and result
                                   sorting, providing a personalized and organized overview of your documents.</p>
                                <p>Adjust the display of your document library to suit your specific needs and
                                   preferences, ensuring that the most relevant information is always visible and easily
                                   accessible.</p>
                                <p>Experience a tailored document management system that adapts to your unique
                                   requirements and enhances productivity by presenting the data you need in the most
                                   efficient manner.</p>
                                <p>AIDA's <strong>customizable interface</strong> ensures a seamless user experience,
                                   allowing you to focus on what truly matters – growing your business.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('archive-page:pageTitle')}</div>
                            <h2><Trans>Share documents securely and efficiently with AIDA's public link feature</Trans>
                            </h2>
                            <Trans i18nKey="archive-page:feature6">
                                <p>Simplify collaboration with AIDA's sharing/public link feature, allowing you to
                                   securely share documents with <strong>anyone</strong> using a simple link.</p>
                                <p>Set an expiration date for added security, and choose between allowing PDF
                                   downloading or displaying the document in view-only mode.</p>
                                <p>AIDA's secure sharing capabilities ensure the <strong>protection of sensitive
                                                                                         data</strong> while promoting
                                   collaboration and streamlining workflows.</p>
                                <p>Enhance teamwork and communication both within your organization and with external
                                   partners by sharing crucial information <strong>quickly and safely</strong>.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/archive/aida-archive-document-management-06-share-documents-safely.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/archive/aida-archive-document-management-07-export-data-multiple-formats.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('archive-page:pageTitle')}</div>
                            <h2><Trans>Export data in various formats for seamless integration and advanced
                                       analytics</Trans></h2>
                            <Trans i18nKey="archive-page:feature7">
                                <p>AIDA's document archive offers the ability to export extracted fields and other
                                   useful information in a <strong>range of formats</strong>, including XLS, JSON, XML,
                                   and CSV.</p>
                                <p>This versatility allows for <strong>seamless integration</strong> with other systems,
                                   facilitating business intelligence, reporting, and analytics efforts.</p>
                                <p>Unlock the full potential of your data by exporting it to your preferred format for
                                   further analysis or integration with other tools.</p>
                                <p>AIDA's powerful export feature ensures that you can make <strong>data-driven
                                                                                                    decisions</strong>,
                                   optimize processes, and stay ahead of the competition in today's fast-paced business
                                   environment.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
};

export default ArchivePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "archive-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'archive-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Archive - Document Management';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};
